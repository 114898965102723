import React, { Component } from 'react'
import cb from '../../Assets/images/cb-cover.png'
import Center from 'react-center'


class Homepage extends Component {
  render() {
    return (
      <homepage>
      <div>  <Center><img className='bg' src={cb} alt="govardhanchitrada.me, govardhan chitrada, Govardhan Chitrada, Govardhan, Chitrada" /></Center></div>
</homepage>
);
  }
}

export default Homepage;